@import '../../Assets/Styles/Helpers/Helpers.Style.scss';

.no-data-result {
  height: 100vh;
  background-color: #f7f9fb;
  @include d-flex-center;

  img {
    height: 100%;
    width: 100%;
  }
}
