.error-boundary-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .error-title {
    display: flex;
    align-items: center;
    color: #303030;
    margin-bottom: 2rem;

    img {
      width: 4rem;
    }
  }
}
