@import '../../Assets/Styles/Master.Style.scss';

.uploader-dilaog-wrapper {
  .MuiDialog-paper {
    max-width: 15rem;
  }

  &.is-remove {
    .MuiDialog-paper {
      max-width: 25rem;
    }
  }

  .uploader-dialog-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .MuiAvatar-root {
      border: 5px solid $c-primary;
      background-color: #f4f4f4;
      color: #d9d9d9;
    }
  }
}

.uploader-container {
  padding-top: 0.5rem;
}

.uploader-wrapper {
  width: 100%;

  .MuiInputBase-root {
    padding: 1rem;
    padding-right: 2rem;
  }

  .inputs .end-adornment-wrapper {
    border-left: 0px solid transparent !important;
  }

  .MuiAvatar-root {
    border: 2px solid $c-primary;
    background-color: #f4f4f4;
    color: #d9d9d9;
  }

  .file-input {
    display: none;
  }

  .counter-text {
    color: $c-secondary;
  }

  .error-wrapper {
    font-size: $fz-12px;
    color: $c-error;
    margin-bottom: 0.5rem;
  }

  .drop-here {
    @include d-inline-flex-v-center;
    padding: 0 0.5rem;
    pointer-events: none;
  }

  .MuiChip-root .MuiChip-avatar {
    width: 18px;
    height: 18px;
  }

  .failed-wrapper,
  .as-overlay,
  .as-overlay-spinner {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba($color: #000000, $alpha: 0.2);
    height: 100%;
    width: 100%;
    min-height: 100px;
    min-width: 100px;
    z-index: 3;
    @include rtl {
      right: 0;
      left: initial;
    }
  }

  .failed-wrapper {
    @include d-flex-center;
    z-index: 0;

    span {
      height: 30px;
      width: 30px;
      min-width: 30px;
      padding: 0.5rem;
      color: $c-white;
      background-color: rgba($c-warning, 0.5);
      border-radius: 100%;
      @include d-inline-flex-center;
      animation: scale-animation 0.7s both;
    }
  }

  .as-overlay {
    color: $c-gray-lighter;
  }

  .as-overlay-spinner {
    @include d-flex-center;
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.25);
  }

  .label-wrapper {
    margin-bottom: 0.5rem;
    padding-left: 0.5rem;
    color: $c-black;
    font-size: $fz-16px;

    &.theme-primary {
      color: $c-primary;
      font-weight: normal;
      font-size: $fz-16px;
    }

    @include rtl {
      padding-left: 0;
      padding-right: 0.5rem;
    }
  }
}

@keyframes scale-animation {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.7);
  }
  100% {
    transform: scale(1);
  }
}
