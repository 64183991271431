.br-0 {
  border-radius: 0 !important;
}

.br-r-1rem-reversed {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: 1rem !important;
  border-bottom-right-radius: 1rem !important;

  @include rtl {
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

.br-l-1rem-reversed {
  border-top-left-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;

  @include rtl {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }
}
