::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background: darkgrey;
  border: 0px none $c-white;
  border-radius: 10rem;

  &:hover {
    background: #cccccc;
  }

  &:active {
    background: #eeeeee;
  }
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background: #666666;
  border: 0px none $c-white;
  border-radius: 10rem;

  &:hover {
    background: #666666;
  }

  //   &:active {
  //     background: #333333;
  //   }
}

::-webkit-scrollbar-corner {
  background: transparent;
}

body::-webkit-scrollbar {
  width: 0.6em;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid transparent;
  border-radius: 10rem;
}
