@import '../../../../Assets/Styles/Master.Style.scss';

.box-theme-component-wrapper {
  min-height: 150px;
  width: 100%;
  position: relative;
  @include d-flex-center;
  flex-wrap: wrap;
  background-color: $c-white;

  .custom-dropzone-wrapper {
    @include d-flex-column-center;
    flex-wrap: wrap;
    position: relative;
    width: 100%;
    padding: 0.5rem;
    border-radius: 4px;
    border: 2px solid $c-gray-light;
    min-height: 190px;
    background-color: #f3fafc;

    .dropzone-items-wrapper {
      @include d-flex-center;
      flex-wrap: wrap;
      max-width: 100%;
    }

    .dropzone-item-wrapper {
      position: relative;
      border-radius: 0.25rem;
      padding: 1rem;
      padding-bottom: 0;
      margin: 0 0.5rem 0.5rem;
      min-height: 100px;
      min-width: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .dropzone-title {
        font-weight: bold;
        font-family: $ff-semibold;
        padding-top: 1rem;
      }

      .dropzone-subtitle {
        color: #a1a1a1;
        font-size: 10px;
        padding-top: 0.5rem;
        font-weight: lighter;
      }

      .dropzone-footer {
        display: flex;
        color: #7d7d7d;
        padding-top: 1.5rem;
        font-weight: lighter;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        width: 100%;

        .mdi::before {
          font-size: 14px;
        }
      }

      .MuiButtonBase-root {
        color: $c-primary;
        padding: 0 0.2rem;
        border-radius: 0.5rem;
      }

      .over-item-actions-wrapper {
        @include d-inline-flex;
        position: absolute;
        top: 0.25rem;
        right: 0;
        @include rtl {
          right: initial;
          left: 0;
        }

        .btn-close,
        .open-gallery-btn,
        .download-btn {
          color: $c-white;
          margin: 0 0.25rem;
          background-color: rgba(0, 0, 0, 0.25);
        }
      }
    }

    .drop-here {
      padding: 0 0.5rem 0.5rem;
      @include d-inline-flex-v-center;
      pointer-events: none;
    }

    .box-theme-image {
      height: 100px;
      width: 100px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }

    .video-wrapper {
      min-height: 100px;
      min-width: 100px;
      max-width: 270px;
    }
  }

  &.drag-over {
    .custom-dropzone-wrapper {
      border-style: dashed;
      border-color: #c1d0d4;
      transition: border-color 0.18s ease-in-out;
    }

    &:hover {
      .custom-dropzone-wrapper {
        border-color: #73c6dd;
        transition: border-color 0.18s ease-in-out;
      }
    }
  }
}
