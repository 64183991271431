.btns.MuiButtonBase-root {
  border-radius: 5px;
  min-height: 34px;
  line-height: 100%;
  min-width: 150px;
  margin: 0 0.25rem;
  padding: 0.219rem 0.5rem;
  text-transform: initial;
  font-size: $fz-13px;
  color: $c-primary;
  white-space: nowrap;
  font-family: $ff-default !important;

  .mdi {
    line-height: 14px;
  }

  &.theme-solid {
    background-color: $c-primary;
    color: $c-white;

    &.bg-cancel {
      background-color: $c-gray-lighter;
      color: $c-black-dark;

      .MuiButton-label {
        color: $c-black-dark;
      }
    }
  }

  &.theme-big {
    color: $c-gray-primary;
    min-height: 35px;
    border-radius: 9px;
    min-width: 130px;
  }

  &.theme-long {
    min-width: 200px;
  }

  &.theme-short {
    min-width: 100px;
  }

  &.theme-short-xs {
    min-width: 70px;
  }
  &.theme-short {
    min-width: 100px;
  }

  &.theme-outline {
    color: $c-gray-primary;
    border: 2px solid $c-primary;
    font-weight: bold;
    font-family: $ff-semibold;

    &.is-danger {
      color: $c-danger;
      border: 2px solid $c-danger;
    }

    &.is-gray {
      border: 2px solid #c1c9d2;
    }
  }

  &.theme-menu {
    @include d-flex-v-center-h-between;
    color: $c-gray-primary;
    width: 100%;
    border-radius: 0;
    min-height: 32px;
    padding: 0.6rem;
    margin-left: 0;
    margin-right: 0;

    &:not([disabled]) {
      &:hover {
        background-color: $c-gray-lighter;
      }
    }
  }

  &.theme-menuselectd {
    @include d-flex-v-center-h-between;
    color: $c-gray-primary;
    width: 100%;
    background-color: #f4f4f4;
    border-radius: 0;
    min-height: 32px;
    padding: 0.5rem;
    margin-left: 0;
    margin-right: 0;

    &:not([disabled]) {
      &:hover {
        background-color: $c-gray-lighter;
      }
    }
  }

  &.theme-wide {
    min-height: 45px;
    width: 90%;
    margin-left: 0;
    margin-right: 0;
    align-items: center;
    font-size: 16px;
    border-radius: 8px;

    &:not([disabled]) {
      &:hover {
        background-color: $c-gray-lighter;
      }
    }
  }

  &.theme-transparent {
    background-color: transparent;
    color: $c-white;

    .MuiButton-label {
      color: $c-white;
    }
  }

  &:disabled {
    background-color: $c-gray-secondary !important;
    border-color: $c-gray-secondary !important;
    cursor: not-allowed;
    pointer-events: initial;
    color: $c-white !important;
  }
}

.btns-icon.MuiButtonBase-root {
  height: 32px;
  width: 32px;
  min-width: 32px;
  max-width: 32px;
  border-radius: 100%;
  color: $c-gray-dark;
  padding: 0.2rem;

  .mdi {
    &::before {
      font-size: 24px;
    }
  }

  &.btns-small {
    width: 24px;
    height: 24px;
    min-width: 24px;
    max-width: 24px;

    .mdi {
      &:before {
        font-size: 18px;
      }
    }
  }

  &.theme-danger {
    background-color: $c-danger;
    color: $c-white;
  }

  &.theme-outline {
    background-color: transparent;
    color: $c-gray-darker;

    &:disabled {
      background-color: transparent !important;
      color: $c-gray !important;
      cursor: not-allowed;
    }

    // &:not([disabled]):hover {
    //   background-color: $c-primary;
    //   color: $c-white !important;
    // }

    &.c-blue-lighter {
      border-color: $c-blue-lighter;
      color: $c-blue-lighter;

      &:not([disabled]):hover {
        background-color: $c-blue-lighter;
        color: $c-white;
        border-color: $c-white;
      }
    }
  }

  &.theme-outline-dark {
    border: 1px solid $c-gray-lighter;
    color: $c-black;
    background-color: $c-white;
  }

  &.theme-transparent {
    background-color: transparent;
    color: $c-gray-dark;

    .mdi {
      color: $c-gray-dark;
    }

    &[disabled] {
      background-color: transparent !important;
      color: $c-gray-light;
      cursor: not-allowed;
    }
  }

  &.theme-action {
    color: $c-white;

    &:not(:focus):not(:active):not(.active) {
      background-color: $c-black-light !important;

      &:hover {
        background-color: $c-primary !important;
      }
    }
  }

  &.theme-solid {
    background-color: $c-primary;
    color: $c-white;
  }

  img {
    width: 100%;
  }

  &:disabled {
    background-color: transparent !important;
    color: $c-gray !important;
    cursor: not-allowed;
    pointer-events: auto;
  }
}

.btns-menu {
  height: 53px;
  width: 57.5px;
}

.btns-tag.MuiButtonBase-root {
  background-color: transparent;
  height: 100%;
  min-height: 100%;
  padding: 0;
  width: 1rem;
  min-width: 1rem;
  transform: rotateZ(-48deg);
  height: 1rem;

  .MuiButton-label {
    color: $c-primary;
  }
}

.bbt-dark {
  .MuiButtonBase-root {
    color: $c-white;
    min-width: 2rem;
    height: 34px;
    padding-left: 9px;
    padding-right: 9px;
    background-color: $c-black;
    color: $c-white;
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
  }

  .mdi {
    color: $c-white;
  }
}

.bbt-gray {
  .MuiButtonBase-root {
    color: $c-white;
    min-width: 2rem;
    height: 2rem;
    padding-left: 9px;
    padding-right: 9px;
    background-color: #f5f5f5;
    color: $c-white;
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
  }

  .mdi {
    color: #878787;
  }
}

.bbt-gray-lite {
  .MuiButtonBase-root {
    min-width: 5rem;
    min-height: 2rem;
    font-weight: 900;
    padding-left: 9px;
    padding-right: 9px;
    background-color: #e2e2e2;
    color: $c-black;
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
  }

  .mdi {
    color: #878787;
  }
}

.bbt-primary {
  .MuiButtonBase-root {
    color: $c-white;
    min-width: 5rem;
    width: 11rem;
    height: 2rem;
    padding-left: 0.7rem;
    padding-right: 0.7rem;
    background-color: $c-blue-light;
    color: $c-white;
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
  }

  .mdi {
    color: $c-white;
  }
}
