@import '../../Assets/Styles/Master.Style';

.loader-wrapper {
  overflow: hidden;
  width: 100%;
  height: 100%;

  .MuiBackdrop-root {
    z-index: 999999;
    overflow: hidden;
    backdrop-filter: saturate(150%) blur(3px);
    background-color: transparent;
  }
}
