@import 'src/Assets/Styles/Helpers/Mixins/Mixins.Style.scss';

.popover-wrapper {
  .MuiPaper-root {
    min-width: 100px;
    border-radius: 12px;
    @include d-flex;
    flex-wrap: wrap;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.21);
    border-radius: 4px;
  }

  &.with-backdrop {
    background-color: rgba(0, 0, 0, 0.3);
  }
}
