$c-primary-dark: #59929b;
$c-primary: #70b6c1;
$c-primary-light: #84e2f028;
$c-secondary: #3cd1f3;
$c-secondary-light: #e8f0ff;
$c-purple: #68529f;
$c-success: #31c7aa;
$c-success-light: #6ae5d3;
$c-warning: #f69e95;
$c-warning-light: #ffe2de;
$c-danger: #df7979;
$c-info: #857bff;
$c-black: #000000;
$c-black-dark: #020311;
$c-black-light: #2d3e50;
$c-white: #ffffff;
$c-white-dark: #dfe9ea;
$c-yellow: #ffb902;
$c-blue: #0066cc;
$c-blue-light: #3e7192;
$c-blue-lighter: #5fb7ff;
$c-blue-lightest: #b6d2ff;
$c-red: #ff0000;
$c-red-light: #f43f5e;
$bg-red-light: #f43f5e;
$c-red-dark: #cc0000;
$c-green-dark: #25d366;
$c-green-primary: #5eb041;
$c-green: #72d179;
$c-green-light: #dbf5ee;
// Start New Colors (Gray)
$c-gray-primary: #838383;
$c-gray-secondary: #808080;
$c-gray-darker: #5c5c5c;
$c-gray-dark: #4b4b4b;
$c-gray: #aeaeb2;
$c-gray-border: #cfcfcf;
$c-gray-light: #f0f0f0;
$c-gray-lighter: #f0f2f5;
$c-gray-lighter-more: #f6f7f9;
$c-gray-lightest: #fafafa;
// End New Colors

$c-lavender: #e1e2fe;
$c-error: #f44336;
$bg-progress-gradient: linear-gradient(90deg, $c-warning 0%, $c-primary 45%, $c-success 100%);
$bg-progress-gradient-reversed: linear-gradient(
  -90deg,
  $c-warning 0%,
  $c-primary 45%,
  $c-success 100%
);
$bg-gradient-primary: linear-gradient(191deg, $c-primary 91%, $c-primary-dark 10%);
$bg-gradient-primary-right: linear-gradient(to right, $c-primary 0%, $c-primary-dark 100%);
$bg-gradient-primary-left: linear-gradient(to left, $c-primary 0%, $c-primary-dark 100%);
$bg-gradient-primary-top: linear-gradient(to top, $c-primary 0%, $c-primary-dark 100%);
$bg-gradient-primary-bottom: linear-gradient(to bottom, $c-primary 0%, $c-primary-dark 100%);
