@import '../../Assets/Styles/Master.Style';

.table-actions-wrapper {
  position: absolute;
  min-width: 100px;
  border-radius: 1rem 0 0 1rem;
  background-color: $c-gray-primary;
  @include rtl {
    border-radius: 0 1rem 1rem 0;
  }

  .btns-icon {
    margin: 0 0.25rem;

    &:disabled {
      background-color: transparent !important;
      color: $c-gray !important;
    }
  }

  z-index: 1;
  @include d-inline-flex-center;
  padding: 0 0.25rem;

  &.theme-original {
    background-color: $c-primary;
  }
}

// Start Table
.table-responsive {
  position: relative;
  padding-top: 1rem;

  .MuiTableContainer-root {
    max-height: calc(100vh - 15rem);
    overflow-y: auto;
    border-radius: 0;
  }
}

.table-wrapper {
  &.MuiTable-root {
    .MuiTableCell-root {
      border-bottom: 0;
      font-family: $ff-default;
      white-space: nowrap;
      @include rtl {
        text-align: right;
      }

      &[draggable='true'] {
        cursor: move;
      }

      &.drag-over-cell {
        box-shadow: inset 0 0 5px 1px rgba(0, 0, 0, 0.2);
        border-radius: 0.25rem 0.25rem 0 0;
      }
    }

    .actions-cell {
      background-color: #f4eee7;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      .actions-cell-item {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        text-align: center;
      }
    }

    .MuiTableHead-root {
      .MuiTableSortLabel-root {
        font-weight: bold;
        font-family: $ff-semibold !important;
        color: #5b5b5b;
      }

      .MuiTableRow-head {
        .MuiTableCell-head {
          background-color: #f4eee7;
          position: sticky;
          top: 0;
          font-size: $fz-table-header;
          font-weight: bold;
          font-family: $ff-semibold !important;
          color: #5b5b5b;
          z-index: 2 !important;
          // width: 50px;

          // &:first-child {
          //   border-top-left-radius: 0.5rem;

          //   @include rtl {
          //     border-top-right-radius: 0.5rem;
          //     border-top-left-radius: 0;
          //   }
          // }

          &.actions-cell,
          &.checkboxes-cell {
            font-size: 12px;
            text-align: center;
          }

          // &:last-child {
          //   border-top-right-radius: 0.5rem;

          //   @include rtl {
          //     border-top-right-radius: 0;
          //     border-top-left-radius: 0.5rem;
          //   }
          // }

          .resize-btn {
            width: 10px;
            height: 100%;
            cursor: col-resize;
            position: absolute;
            z-index: 1;
            min-height: 37px;
            top: 0;
            right: 0;
            @include rtl {
              right: initial;
              left: 0;
            }
            background-color: transparent;

            &:hover {
              box-shadow: inset 0 0 0.25rem 0.25rem rgba($c-primary, 0.25);
            }
          }

          .filter-btn {
            color: #5b5b5b;
            font-weight: bold;
            font-family: $ff-semibold !important;
            margin: 0;
            min-width: 0;
            transition: all 0.1s ease-in-out;

            &:hover {
              background-color: $c-primary;
              color: $c-white;
            }

            &.is-active {
              background-color: $c-primary;
              color: $c-white;
            }

            .mdi {
              &::before {
                font-size: 20px;
              }
            }
          }
        }
      }
    }

    .MuiTableBody-root {
      &.is-odd {
        .MuiTableRow-root {
          border: none;

          .MuiTableCell-body {
            background-color: $c-gray-lightest;
          }

          &:nth-child(odd) {
            .MuiTableCell-body {
              background-color: white !important;
            }
          }

          &:hover,
          &.table-row-overlay {
            .MuiTableCell-body {
              background-color: $c-primary-light !important;
            }
          }
        }
      }

      .MuiTableRow-root {
        border-bottom: 0.5px solid #e3e3e3;
        border-left: 1px solid #e3e3e3;
        border-right: 0.5px solid #e3e3e3;

        .MuiTableCell-body {
          height: 40px;
          background-color: white;
          color: $c-black-light;
          font-size: $fz-table-footer;
          font-weight: $fw-bold;
          transition: border, 0.1s ease-in-out;

          &.actions-cell-wrapper {
            text-align: right;
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 4rem;
            padding-right: 0;
          }
        }

        &:nth-child(odd) {
          .MuiTableCell-body {
            background-color: white !important;
          }
        }

        &:hover,
        &.table-row-overlay {
          .MuiTableCell-body {
            background-color: $c-primary-light !important;
          }
        }

        &.has-validation {
          transition: border, 0.1s ease-in-out;
          border: 3px solid $c-danger;

          &:first-child {
            border-bottom: 2px solid $c-danger;
          }
        }

        &.is-disabled {
          .MuiTableCell-body {
            background-color: $c-gray-lighter !important;
            color: $c-gray !important;
          }
        }
      }
    }

    .MuiTableFooter-root {
      .MuiTableRow-footer {
        .MuiTableCell-footer {
          background-color: $c-gray-lighter;
          color: $c-black-light;
          font-size: $fz-table-footer;
        }
      }
    }

    &.theme-original {
      .MuiTableHead-root {
        .MuiTableRow-head {
          .MuiTableCell-head {
            background-color: $c-primary;
            color: $c-white;
          }
        }
      }

      .MuiTableBody-root {
        .MuiTableRow-root {
          .MuiTableCell-body {
            background-color: $c-white;
            color: $c-gray-primary;
          }

          &:nth-child(even) {
            .MuiTableCell-body {
              background-color: $c-gray-lightest;
            }
          }

          &:hover,
          &.table-row-overlay {
            .MuiTableCell-body {
              background-color: $c-blue-lightest;
            }
          }
        }
      }

      .MuiTableFooter-root {
        .MuiTableRow-footer {
          .MuiTableCell-footer {
            background-color: $c-blue-lighter;
            color: $c-white;
            font-size: $fz-table-footer;
          }
        }
      }
    }
  }
}

.table-responsive {
  .pagination-component-wrapper {
    padding: 0.5rem;
    background-color: $c-white;
    border-radius: 0 0 0.5rem 0.5rem;
  }
}

//  Start Table Pagination (original)
.pagination-wrapper {
  background-color: $c-white;
  color: $c-blue-lighter;
  border-radius: 0 0 0.5rem 0.5rem;

  .MuiTypography-root {
    color: $c-blue-lighter;
  }

  .MuiTablePagination-toolbar {
    padding: 0.25rem 0.5rem;
    min-height: unset;
  }

  .select-wrapper {
    background-color: $c-gray-lightest;
    border-radius: 1rem;
    margin: 0 0.5rem;
    overflow: hidden;
    min-width: 70px;
    color: $c-primary;

    path {
      fill: $c-primary;
    }

    .MuiSelect-select {
      width: 100%;
      @include d-flex-v-center-h-between;

      @include rtl {
        padding-left: 24px;
        padding-right: 0.5rem;
      }
    }

    .MuiSelect-icon {
      @include rtl {
        right: initial;
        left: 0;
      }
    }
  }

  .btns-icon {
    &.theme-transparent {
      color: $c-primary;
    }
  }
}

.MuiTablePagination-root {
  .MuiTablePagination-actions {
    svg {
      @include rtl {
        transform: scaleX(-1);
      }
    }
  }
}

.table-cellOpation {
  width: 20px;

  &.is-with-line {
    border-left: 1.5px solid $c-gray-secondary;
    @include rtl {
      border-right: 1.5px solid $c-gray-secondary;
      border-left: none;
    }
  }
}

.selected-filter-amount {
  border-radius: 50%;
  background-color: $c-primary-dark;
  color: $c-white;
  width: 1rem;
  height: 1rem;
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  font-family: $ff-default;
}

.filters-table-popover-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  max-width: 20rem;
  min-width: 20rem;
  padding-top: 1rem;

  .input-wrapper.theme-gray .text-field-wrapper .inputs .MuiInputBase-root {
    flex-wrap: wrap !important;
    justify-content: flex-start !important;
  }

  .filter-search-wrapper {
    margin-bottom: 0.5rem;

    .MuiChip-root {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      margin-top: 0.5rem;
      background-color: #5a5a5a;
      border-radius: 4px;
      color: $c-white;
      height: 1.5rem;
    }
  }

  .filter-body {
    width: 100%;

    .MuiList-root {
      width: 100%;
      padding: 1rem;
      padding-top: 0;
    }

    .filter-values-wrapper {
      max-height: 20rem;
      overflow-y: auto;
    }
  }
}
