@font-face {
  font-family: Inter-Regular;
  font-display: swap;
  src: url('../../../Fonts/Inter/Inter-Regular.ttf');
}

@font-face {
  font-family: Inter-Black;
  font-display: swap;
  src: url('../../../Fonts/Inter/Inter-Black.ttf');
}

@font-face {
  font-family: Inter-Bold;
  font-display: swap;
  src: url('../../../Fonts/Inter/Inter-Bold.ttf');
}

@font-face {
  font-family: Inter-ExtraBold;
  font-display: swap;
  src: url('../../../Fonts/Inter/Inter-ExtraBold.ttf');
}

@font-face {
  font-family: Inter-ExtraLight;
  font-display: swap;
  src: url('../../../Fonts/Inter/Inter-ExtraLight.ttf');
}

@font-face {
  font-family: Inter-Light;
  font-display: swap;
  src: url('../../../Fonts/Inter/Inter-Light.ttf');
}

@font-face {
  font-family: Inter-Medium;
  font-display: swap;
  src: url('../../../Fonts/Inter/Inter-Medium.ttf');
}

@font-face {
  font-family: Inter-SemiBold;
  font-display: swap;
  src: url('../../../Fonts/Inter/Inter-SemiBold.ttf');
}

@font-face {
  font-family: Inter-Thin;
  font-display: swap;
  src: url('../../../Fonts/Inter/Inter-Thin.ttf');
}
